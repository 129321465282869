<template>
  <FragrancesModal
    ref="modal"
    :fragranceStages="fragranceStages"
    :imageTitle="imageTitle"
    :title="title"
    :perfumeDescriptions="perfumeDescriptions"
  />
  <div class="absolute h-0 inset-x-0 top-16 top-4-mobile select-none z-10">
    <h1
      id="slider-title"
      @click="$refs.modal.openSlide(0)"
      class="ui-pill max-w-max mx-auto cursor-pointer pointer-events-auto title-slide"
      :class="
        `${overlayConfiguration.titleTextColor} ${overlayConfiguration.titleBackgroundColor}`
      "
    >
      {{ truncateOnlyOnMobile(title, truncateTitleLength, '...') }}
    </h1>
  </div>

  <div
    :class="`absolute h-4/5 right-3 lg:right-6 pointer-events-none select-none`"
  >
    <div
      :class="
        `relative h-full w-6 overflow-hidden bg-opacity-60 ${overlayConfiguration.timelineColor} rounded-xl z-10`
      "
    >
      <div
        v-for="(fragranceSettings, k, index) in fragranceStagesDefaultSettings[activeSlide]"
        v-bind:key="k"
        class="absolute w-full text-xs text-center font-medium z-30"
        :class="`time-step-${index + 1} ${overlayConfiguration.timelineTextColor}`"
      >
        <div v-if="fragranceSettings.isVisible">
          {{ fragranceSettings.time }} <br />
          {{ fragranceSettings.unit }}
        </div>
      </div>
      <div
        :class="
          `absolute w-full h-full bottom-0 ${overlayConfiguration.timelineColor} z-20`
        "
        v-bind:style="[fragranceStages[activeSlide].isInvert ?
          {
            animation: `timeline-bar-progress-invert ${evaporationDuration}s linear`,
          }
         :
         {
            animation: `timeline-bar-progress ${evaporationDuration}s linear`,
          }
        ]"
      ></div>
    </div>
    <div class="absolute h-full w-10 z-10 right-10 top-0">
      <template
        v-for="(stage, k) in fragranceStages[activeSlide].ingredients"
        v-bind:key="`fragrance-label-${ parseInt(k) + 1 }`"
      >
        <!-- TODO find a way to center vertically -->
        <div
          :class="`absolute label label-step-${ parseInt(k) + 1 }`"
          :style="{
            top: null,
          }"
        >
          <template
            v-for="(ingredient) in stage"
            v-bind:key="`${k}-${ingredient.fragrance.name}`"
          >
            <h2
              @click="handleIngredientClick(ingredient.fragrance)"
              class="absolute ui-pill cursor-pointer pointer-events-auto ingredient-title"
              :class="
                `!${overlayConfiguration.pillTextColor} !${overlayConfiguration.pillBackgroundColor}`
              "
            >
              {{ truncateOnlyOnMobile(ingredient.fragrance.name, truncatePillLength, '...') }}
            </h2>
            <img
              @click="handleIngredientClick(ingredient.fragrance)"
              class="cursor-pointer pointer-events-auto"
              :src="ingredient.fragrance.image"
              @error="setAltImg"
              alt=""
            />
          </template>
        </div>
      </template>
    </div>
  </div>
  <div
    id="particle-container"
    class="fixed w-full h-full pointer-events-none select-none"
    :class="{ inverted: fragranceStages[activeSlide].isInvert }"
  />
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import FragrancesModal from "./FragrancesModal.vue";

export default {
  name: "FragranceExperienceOverlay",
  components: {
    FragrancesModal,
  },
  data: () => ({
    evaporationDuration: 30,
    evaporationElapsed: 1,
    evaporationInterval: null,
    truncatePillsUnderWidthSize: 510,
    truncatePillLength: 28,
    truncateTitleLength: 14,
  }),
  props: {
    title: String,
    imageTitle: String,
    fragranceStages: Array,
    fragranceStagesDefaultSettings: Object,
    perfumeDescriptions: Object,
    overlayConfiguration: Object,
    activeSlide: Number,
  },
  mounted() {
    this.evaporationInterval = setInterval(() => {
      this.evaporationElapsed++;
      this.generateParticles();
      if (this.evaporationElapsed > this.evaporationDuration) {
        clearInterval(this.evaporationInterval);
      }
    }, 1000);
    window.addEventListener('resize', this.onWidthChange);
  },
  beforeUnmount() {
    if (this.evaporationInterval) {
      clearInterval(this.evaporationInterval);
    }
    window.removeEventListener('resize', this.onWidthChange);
  },
  methods: {
    generateParticles() {
      for (const ingredient in this.fragranceStages[this.activeSlide].ingredients) {
        for (const fragrance in this.fragranceStages[this.activeSlide].ingredients[ingredient]) {
          const ingredientData = this.fragranceStages[this.activeSlide].ingredients[ingredient][fragrance];

          this.spawnParticles(ingredientData.note, ingredientData.fragrance);
        }
      }
    },
    spawnParticles(stage, ingredient) {
      const intensity = this.getIntensity(stage) / 2;
      if (intensity && Math.random() <= intensity) {
        const container = document.getElementById("particle-container");
        const elem = document.createElement("div");
        const size = anime.random(50, 100);
        const img = new Image();
        const imgSrc = ingredient.image;
        const that = this;
        // need to wait for onload to get width/height of the image
        img.onload = function() {
          const left = anime.random(0, window.innerWidth - size);
          elem.className = "absolute";
          // Use scale factor to display properly rectangle images (e.g. 60 x 100 px)
          const scaleFactor = this.width / this.height;
          elem.style = `width: ${size *
            scaleFactor}px; height: ${size}px; left: ${left}px; bottom: -${size}px; background: url("${imgSrc}") center / contain; background-size:100% 100%;`;
          container.appendChild(elem);
          that.animate(elem, size);
        };
        img.src = ingredient.image;
      }
    },
    animate(elem, size) {
      const direction = Math.random() > 0.5 ? -1 : 1;
      anime({
        targets: elem,
        rotate: 360 * anime.random(1.5, 2.5) * direction,
        left: anime.random(0, window.innerWidth - size - 30),
        bottom: window.innerHeight + size,
        easing: "linear",
        duration: anime.random(12000, 15000),
        complete: function() {
          elem.remove();
        },
      });
    },
    getIntensity(stage) {
      const intensityIntervals = this.fragranceStagesDefaultSettings[this.activeSlide][stage].intensity;
      const currentTime =
        (this.evaporationElapsed / this.evaporationDuration) * 100;
      if (
        currentTime > intensityIntervals[0] &&
        currentTime <= intensityIntervals[1]
      ) {
        return (
          (currentTime - intensityIntervals[0]) /
          (intensityIntervals[1] - intensityIntervals[0])
        );
      } else if (
        currentTime > intensityIntervals[1] &&
        currentTime < intensityIntervals[2]
      ) {
        return (
          1 -
          (currentTime - intensityIntervals[1]) /
            (intensityIntervals[2] - intensityIntervals[1])
        );
      } else {
        return 0;
      }
    },
    setAltImg(event) {
      event.target.src = require("@/assets/transparent40.png");
    },
    handleIngredientClick(ingredient) {
      if (ingredient.description) {
        this.$refs.modal.openSlide(ingredient);
      }
    },
    truncateOnlyOnMobile: function (text, length, suffix) {
      if (text.length > length && window.screen.width <= this.truncatePillsUnderWidthSize) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
    onWidthChange() {
      document.getElementsByClassName('ingredient-title').forEach((pill, index) => {
        const stage = parseInt(index/3);
        const ingredientIndex = index - stage * 3;

        pill.innerText = this.truncateOnlyOnMobile(this.fragranceStages[this.activeSlide].ingredients[stage][ingredientIndex].fragrance.name, this.truncatePillLength, '...');
      });

      document.getElementById('slider-title').innerText = this.truncateOnlyOnMobile(this.title, this.truncateTitleLength, '...');
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/typography.scss";

$fragrance-label-distance: 10px;
$fragrance-label-image-height: 40px;
$fragrance-label-image-size: 40px;
$fragrance-label-step-y-offset: $fragrance-label-image-size / 2 +
  $fragrance-label-distance / 2;
$line-height: 1.3em;
$peak-points: 80%, 50%, 20%;

@for $i from 1 through length($peak-points) {
  $top: 100% - nth($peak-points, $i);
  .time-step-#{$i} {
    top: calc(#{$top} - #{$line-height} - 1px);
  }
  .label-step-#{$i} {
    top: calc(#{$top} - #{$line-height});
    // top: calc(#{$top} - #{$line-height} - #{$fragrance-label-step-y-offset});
  }
}

.label {
  img:first-of-type {
    margin-bottom: $fragrance-label-distance;
    height: $fragrance-label-image-height;
  }
  h2 {
    right: 50px;
  }
  h2:first-of-type {
    top: 10px;
  }
  h2:nth-of-type(2) {
    top: 55px;
  }
  h2:nth-of-type(3) {
    top: 100px;
  }
  img {
    width: $fragrance-label-image-size;
    height: $fragrance-label-image-height;
    position: relative;
    top: 10px;
  }
  img:first-of-type {
    top: 10px;
  }
  img:nth-of-type(2) {
    top: 5px;
  }
}
.title-slide {
  @extend .g-text-h2;
  height: 68px;
}

#particle-container.inverted {
  transform: rotate(180deg);
}
</style>
