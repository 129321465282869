<template>
  <div
    class="questionnaire-slide"
    :class="{ 'questionnaire-slide--dark': theme === 'dark' }"
    ref="questionnaireSlide"
  >
    <div id="questionnaire-slide__fragrance-ui-pill" class="questionnaire-slide__fragrance ui-pill">{{ truncateOnlyOnMobile(fragrance, truncateTitleLength, '...') }}</div>
    <div class="questionnaire-slide__content">
      <div
        class="questionnaire-slide__questions"
        ref="scrollContainer"
        @scroll.passive="toggleShadow"
      >
        <div class="questionnaire-slide__header">
          <div id="questionnaire-slide__title" class="questionnaire-slide__title">{{ truncateOnlyOnMobile(title, truncateTitleLength, '...') }}</div>
          <div id="questionnaire-slide__subtitle" class="questionnaire-slide__subtitle">{{ truncateOnlyOnMobile(subtitle, truncateTitleLength, '...') }}</div>
        </div>
        <div class="questions">
          <div :key="item.id" v-for="item in originalQuestions">
            <question
              :success="success"
              :theme="theme"
              :modelValue="item.answer"
              :check="checkSubmit"
              @update:modelValue="
                (newValue) => {
                  item.answer = newValue;
                }
              "
              :question="item.text"
            />
          </div>
        </div>
      </div>
      <div
        class="questionnaire-slide__questions_gradient top"
        :class="{ 'is-active': shadow.top }"
      ></div>
      <div
        class="questionnaire-slide__questions_gradient bottom"
        :class="{ 'is-active': shadow.bottom }"
      ></div>
    </div>
    <div>
      <ui-button
        class="w-full sm:w-auto"
        :disabled="submitDisabled || success"
        @click="submitQuestionnaire"
        size="large"
      >
        Submit
      </ui-button>
    </div>
  </div>
</template>

<script>
import Question from "./Question.vue";
import UiButton from "@/components/ui/UiButton.vue";
import {isMobile} from "@/services/mobile";

export default {
  name: "QuestionnaireSlide",
  components: {
    Question,
    UiButton,
  },
  props: [
    "slideId",
    "fragrance",
    "title",
    "subtitle",
    "theme",
    "questions",
    "activeSlide",
  ],
  data: () => {
    return {
      shadow: {
        top: false,
        bottom: false,
      },
      maxCount: 300,
      submitDisabled: true,
      originalQuestions: [],
      hasError: false,
      success: false,
      truncateTitleUnderWidthSize: 510,
      truncateTitleLength: 14,
    };
  },
  watch: {
    activeSlide() {
      this.makeAnswers();
    },
  },
  methods: {
    async submitQuestionnaire() {
      const answers = this.originalQuestions
        .filter((question) => {
          if (question.answer) {
            return question;
          }
        })
        .map((el) => ({
          slide_questionnaire_question_id: el.id,
          answer: el.answer,
        }));

      if (answers.filter((el) => el.answer.length > this.maxCount).length > 0) {
        this.$moshaToast(
          {
            title: "Questionnaire submission failed",
            description: `Validation failed, at least one question is longer than ${this.maxCount} characters.`,
          },
          {
            type: "danger",
            position: "top-center",
            showCloseButton: false,
          }
        );
        this.hasError = true;
        this.submitDisabled = false;
        return;
      }

      try {
        await fetch(
          `${process.env.VUE_APP_ROOT_API}/slide/${this.slideId}/questionnaire`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({ answers }),
          }
        );
        this.success = true;
        this.$moshaToast("Questionnaire successfully submitted", {
          type: "success",
          position: "top-center",
          showCloseButton: false,
        });
      } catch (e) {
        this.hasError = true;
        this.submitDisabled = false;
        this.$moshaToast(
          {
            title: "Questionnaire submission failed",
            description: "Please try again",
          },
          {
            type: "danger",
            position: "top-center",
            showCloseButton: false,
          }
        );
      }
    },
    toggleShadow() {
      if (this.theme === "dark") {
        const hasVerticalScrollbar =
          this.$refs.scrollContainer.clientHeight <
          this.$refs.scrollContainer.scrollHeight;
        const scrolledFromTop =
          this.$refs.scrollContainer.offsetHeight +
          this.$refs.scrollContainer.scrollTop;
        const scrolledToTop = this.$refs.scrollContainer.scrollTop === 0;
        const scrolledToBottom =
          scrolledFromTop >= this.$refs.scrollContainer.scrollHeight;
        this.shadow.top = hasVerticalScrollbar && !scrolledToTop;
        this.shadow.bottom = hasVerticalScrollbar && !scrolledToBottom;
      }
    },
    checkSubmit() {
      const index = this.originalQuestions.findIndex(
        (el) => el.answer.length > 0 && el.answer.length <= this.maxCount
      );
      this.submitDisabled = index >= 0 ? false : true;
    },
    makeAnswers() {
      this.originalQuestions = this.questions.map((question) => ({
        ...question,
        answer: "",
      }));
    },
    setQuestionnaireButtonPosition() {
      const slidePagination = document.getElementsByClassName("splide__pagination smellviz-pagination")[0];
      const slidePaginationHeight = slidePagination.getBoundingClientRect().height;
      const slidePaginationStyle = slidePagination.currentStyle || window.getComputedStyle(slidePagination, null);
      const slidePaginationBottom = parseInt(slidePaginationStyle.getPropertyValue("bottom"));
      let spaceBetweenSlidePaginationAndQuestionnaireSlide = 10;

      if (isMobile.any()) {
        spaceBetweenSlidePaginationAndQuestionnaireSlide += 55;
      }

      this.$refs.questionnaireSlide.style.paddingBottom = slidePaginationHeight + slidePaginationBottom + spaceBetweenSlidePaginationAndQuestionnaireSlide + "px";
    },
    truncateOnlyOnMobile: function (text, length, suffix) {
      if (text.length > length && window.screen.width <= this.truncateTitleUnderWidthSize) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
    onWidthChange() {
      document.getElementById('questionnaire-slide__title').innerText = this.truncateOnlyOnMobile(this.title, this.truncateTitleLength, '...');
      document.getElementById('questionnaire-slide__subtitle').innerText = this.truncateOnlyOnMobile(this.subtitle, this.truncateTitleLength, '...');
      document.getElementById('questionnaire-slide__fragrance-ui-pill').innerText = this.truncateOnlyOnMobile(this.fragrance, this.truncateTitleLength, '...');
    }
  },
  mounted() {
    this.toggleShadow();
    this.makeAnswers();

    const self = this;

    setTimeout(function () {
      self.setQuestionnaireButtonPosition();
    }, 300);

    window.addEventListener('load', this.setQuestionnaireButtonPosition);
    window.addEventListener('resize', this.setQuestionnaireButtonPosition);
    window.addEventListener('resize', this.onWidthChange);
  },
  beforeUnmount() {
    window.removeEventListener('load', this.setQuestionnaireButtonPosition);
    window.removeEventListener('resize', this.setQuestionnaireButtonPosition);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/typography.scss";
@import "@/styles/colors.scss";

$md: 640px;
.questionnaire-slide {
  background: #0000000d;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  padding-top: var(--s-16);
  padding-bottom: var(--s-16);
  padding-left: var(--s-2);
  padding-right: var(--s-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--s-8);

  &__fragrance {
    @extend .g-text-h2;
  }

  &__title {
    @extend .g-text-h1;
  }

  &__error {
    color: var(--error);
    @extend .g-text-body-regular;
    margin-bottom: 22px;
  }

  &__content {
    flex: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    min-height: 0;
    width: 100%;

    @media (max-width: $md) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__subtitle {
    @extend .g-text-body-large;
    margin-top: 15px;
    @media (max-width: $md) {
      margin-top: 5px;
    }
  }

  &__questions {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 15px;
    margin-top: 30px;
    scroll-margin-top: 10px;
    gap: 35px;

    @media (max-width: $md) {
      width: 100%;
    }

    &_gradient {
      transform: rotate(-180deg);
      position: absolute;
      margin-left: -15px;
      width: 100%;
      opacity: 0;
      pointer-events: none;
      max-height: 0;
      transition: max-height 1s;

      &.top {
        top: 0;
        right: 0;
        background: linear-gradient(
          180deg,
          rgba(25, 25, 25, 0) 0%,
          #191919 100%
        );
      }

      &.bottom {
        bottom: -2px;
        right: 0;
        background: linear-gradient(
          180deg,
          #191919 0%,
          rgba(25, 25, 25, 0) 100%
        );
        border-top: 2px solid rgba(0, 0, 0, 0.25);
      }
      &.is-active {
        max-height: 103px;
        height: 103px;
        transition: max-height 1s;
        opacity: 1;
      }
    }

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #525252;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 2px;
      margin-top: 175px;
      margin-bottom: 90px;
    }
  }

  &__submit {
    padding-top: 20px;
    margin-bottom: 45px;
    width: 100%;
    @media (max-width: $md) {
      min-height: 15vh; // hack for iOS
    }
  }

  &--dark {
    background: #ffffff1a;
    color: #ffffff;

    .questionnaire-slide__fragrance {
      background: #000000;
    }

    .questionnaire-slide__questions {
      &::-webkit-scrollbar-thumb {
        background-color: #f5f5f5;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-track {
        background: #525252;

        border-radius: 2px;
        margin-top: 175px;
        margin-bottom: 90px;
      }
    }
  }
}

.questions {
  display: flex;
  flex-direction: column;
  gap: var(--s-2);
  max-height: 200px;
}
</style>
