<template>
  <div
    class="ui-rating"
    :style="{
      '--color': `var(--${colorValue}-500)`,
      '--hover-color': `var(--${colorValue}-400)`,
    }"
    @mouseleave="highlightedRating = selectedRating"
  >
    <button
      v-for="i in maxStars"
      :key="i"
      :disabled="disabled"
      @mouseover="highlight(i)"
      @click="select(i)"
    >
      <Icon v-if="i <= highlightedRating" icon="mdi:star" />
      <Icon v-else icon="mdi:star-outline" />
    </button>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";

export default {
  name: "UiRating",
  components: {
    Icon,
  },
  emits: ["select"],
  data() {
    return {
      highlightedRating: 0,
      selectedRating: 0,
    };
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "luminous-sand",
    },
    maxStars: {
      type: Number,
      default: 6,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorValue() {
      return `${this.color}`;
    },
  },
  methods: {
    highlight(rating) {
      if (!this.disabled) {
        this.highlightedRating = rating;
      }
    },
    select(rating) {
      this.selectedRating = rating;
      this.$emit("select", rating);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/typography.scss";
@import "@/styles/colors.scss";

.ui-rating {
  display: flex;
  flex-wrap: nowrap;
  gap: var(--s-2);
  font-size: var(--size, 48px);

  button {
    svg {
      color: var(--color);
    }

    &[disabled] {
      svg {
        color: var(--neutral-300) !important;
        cursor: not-allowed;
      }
    }
  }
}
</style>
