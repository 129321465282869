<template>
  <div class="hologram-slide">
    <ui-button variant="solid" size="large" @click="openHologram(title)">
      Start experience
    </ui-button>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "HologramSlide",
  components: {
    UiButton,
  },
  props: {
    title: {
      type: String,
    },
  },
  methods: {
    openHologram(title) {
      window.location.href = "/holograms/" + title.replaceAll(" ", "-");
    },
  },
};
</script>

<style scoped lang="scss">
.hologram-slide {
  background: #0000000d;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
