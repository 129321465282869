<template>
  <div
    class="questions__question"
    :class="{ 'questions--dark': theme === 'dark' }"
  >
    <p class="questions__title">{{ question }}</p>
    <div class="questions__question_wrapper">
      <textarea
        class="questions__question_textarea"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :maxlength="maxCount"
        :rows="minRows"
        ref="textarea"
        placeholder="Please enter a text ..."
        :disabled="success"
      ></textarea>
      <span class="questions__question_counter">
        {{ remainingCount }}/{{ maxCount }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Question",
  props: ["modelValue", "question", "theme", "check", "success"],
  emits: ["update:modelValue"],
  data() {
    return {
      maxCount: 300,
      minRows: 4,
      lineHeight: 20,
      padding: 20,
    };
  },
  computed: {
    remainingCount() {
      return this.maxCount - this.modelValue.length;
    },
  },
  updated() {
    this.resize();
  },
  mounted() {
    this.resize();
  },
  methods: {
    resize() {
      const { textarea } = this.$refs;
      const rows = ~~(
        (textarea.scrollHeight - this.padding * 2) /
        this.lineHeight
      );
      textarea.style.height = this.lineHeight * this.minRows + 40 + "px";
      this.minRows = rows;
    },
  },
  watch: {
    modelValue() {
      this.check();
    },
    remainingCount(newValue) {
      if (this.modelValue) {
        this.hasError = newValue < 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/typography.scss";
@import "@/styles/colors.scss";

.questions {
  display: flex;
  flex-direction: column;

  &--dark {
    .questions__title {
      color: #ffffff;
    }

    .questions__question_textarea {
      border: 1px solid rgba(255, 255, 255, 0.25);
      caret-color: #ffffff;
      color: #ffffff;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .questions__question_counter {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &__title {
    text-align: left;
    @extend .g-text-body-large;
  }

  &__question {
    display: flex;
    flex-direction: column;
    gap: var(--s-2);

    &_wrapper {
      position: relative;
    }

    &_textarea {
      @extend .g-text-body-regular;
      resize: none;
      width: 100%;
      min-height: 121px;
      background: transparent;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      padding: 20px;
      outline: none;
      overflow: hidden;

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &_counter {
      @extend .g-text-body-extra-small;
      position: absolute;
      right: 20px;
      bottom: 20px;
      color: rgba(var(--black), 0.5);
    }
  }
}
</style>
