<template>
  <div class="page-404">
    <div class="card">
      <img class="page-404__logo" src="@/assets/g_logo.svg" alt="" />
      <h1 class="card__title">Experience disabled</h1>
      <a href="https://www.google.com">
        <ui-button variant="solid" size="large">Go back</ui-button>
      </a>
    </div>
    <img
      class="page-404__logo-dbh"
      src="@/assets/givaudan_logo.svg"
      alt="Logo"
    />
  </div>
</template>

<script>
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "Page404",
  components: {
    UiButton,
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/typography.scss";

.page-404 {
  background-color: var(--neutral-700);
  min-height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: var(--s-16);
  padding-top: var(--s-16);
  padding-bottom: var(--s-16);
  padding-left: var(--s-2);
  padding-right: var(--s-2);

  &__logo {
    height: 200px;
    // mimics the color of var(--luminous-sand-500)
    filter: invert(91%) sepia(44%) saturate(799%) hue-rotate(308deg)
      brightness(97%) contrast(99%);
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--s-8);

    &__title {
      @extend .g-text-display;
      color: var(--white);
      text-align: center;
      max-width: 400px;
    }
  }

  &__logo-dbh {
    margin-top: auto;
    max-height: 48px;
    filter: invert(1);
  }
}
</style>
