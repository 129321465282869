<template>
  <button
    :class="['ui-button', variantClass, sizeClass]"
    :style="{
      '--color': `var(--${colorValue}-500)`,
      '--hover-color': `var(--${colorValue}-400)`,
    }"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "UiButton",
  props: {
    variant: {
      type: String,
      default: "solid",
      validator: (value) => ["solid", "ghost", "text"].includes(value),
    },
    size: {
      type: String,
      default: "medium",
      validator: (value) => ["small", "medium", "large"].includes(value),
    },
    color: {
      type: String,
      default: "luminous-sand",
    },
  },
  computed: {
    variantClass() {
      return `--variant-${this.variant}`;
    },
    sizeClass() {
      return `--size-${this.size}`;
    },
    colorValue() {
      return `${this.color}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/typography.scss";
@import "@/styles/colors.scss";

.ui-button {
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  border-radius: 6px;

  &[disabled] {
    background-color: var(--neutral-300) !important;
    cursor: not-allowed;
  }

  &.--variant-solid {
    background-color: var(--white);
    color: var(--neutral-500);

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--hover-color);
    }
  }

  &.--variant-ghost {
    background-color: transparent;
    border: 2px solid var(--color);
    color: var(--color);

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--hover-color);
      border: 2px solid var(--hover-color);
      color: var(--white);
    }
  }

  &.--variant-text {
    color: var(--color);
    text-decoration: underline;

    &:hover,
    &:focus {
      outline: none;
      color: var(--hover-color);
    }
  }

  &.--size-small {
    @extend .g-text-body-small;
    padding: 7px 16px;
  }

  &.--size-medium {
    @extend .g-text-body-regular;
    padding: 8px 24px;
  }

  &.--size-large {
    @extend .g-text-body-large;
    padding: 10px 32px;
  }
}
</style>
