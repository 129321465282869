<template>
  <div class="rating-slide" :class="{ 'rating-slide--dark': theme === 'dark' }" ref="ratingSlide">
    <div id="rating-slide__fragrance-ui-pill" class="ui-pill rating-slide__fragrance">{{ truncateOnlyOnMobile(fragrance, truncateTitleLength, '...') }}</div>
    <div class="rating-slide__mid">
      <div id="rating-slide__title" class="rating-slide__title">{{ truncateOnlyOnMobile(title, truncateTitleLength, '...') }}</div>
      <div id="rating-slide__subtitle" class="rating-slide__subtitle">{{ truncateOnlyOnMobile(subtitle, truncateTitleLength, '...') }}</div>
      <div class="rating-slide__selector ui-pill">
        <ui-rating :disabled="selectionDisabled" @select="selectRating" />
      </div>
    </div>
    <div>
      <ui-button
        class="w-full sm:w-auto"
        :disabled="submitDisabled"
        @click="submitRating"
        size="large"
      >
        Submit
      </ui-button>
    </div>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UiButton.vue";
import UiRating from "@/components/ui/UiRating.vue";
import {isMobile} from "@/services/mobile";

export default {
  name: "RatingSlide",
  components: {
    UiButton,
    UiRating,
  },
  props: ["slideId", "fragrance", "title", "subtitle", "theme"],
  data() {
    return {
      selectedRating: 0,
      submitDisabled: true,
      selectionDisabled: false,
      truncateTitleUnderWidthSize: 510,
      truncateTitleLength: 14,
    };
  },
  mounted () {
    const self = this;

    setTimeout(function () {
      self.setRatingButtonPosition();
    }, 300);

    window.addEventListener('load', this.setRatingButtonPosition);
    window.addEventListener('resize', this.setRatingButtonPosition);
    window.addEventListener('resize', this.onWidthChange);
  },
  beforeUnmount() {
    window.removeEventListener('load', this.setRatingButtonPosition);
    window.removeEventListener('resize', this.setRatingButtonPosition);
  },
  methods: {
    selectRating(rating) {
      this.selectedRating = rating;
      this.submitDisabled = false;
    },
    async submitRating() {
      this.submitDisabled = true;
      this.selectionDisabled = true;

      try {
        await fetch(
          `${process.env.VUE_APP_ROOT_API}/slide/${this.slideId}/ratings`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              rating: this.selectedRating,
            }),
          }
        );

        this.$moshaToast("Rating successfully submitted", {
          type: "success",
          position: "top-center",
          showCloseButton: false,
        });
      } catch (e) {
        console.error(e);
        this.$moshaToast(
          {
            title: "Rating submission failed",
            description: "Please try again",
          },
          {
            type: "danger",
            position: "top-center",
            showCloseButton: false,
          }
        );
        this.submitDisabled = false;
      }
    },
    setRatingButtonPosition() {
      const slidePagination = document.getElementsByClassName("splide__pagination smellviz-pagination")[0];
      const slidePaginationHeight = slidePagination.getBoundingClientRect().height;
      const slidePaginationStyle = window.getComputedStyle(slidePagination);
      const slidePaginationBottom = parseInt(slidePaginationStyle.getPropertyValue("bottom"));
      let spaceBetweenSlidePaginationAndRatingSlide = 10;

      if (isMobile.any()) {
        spaceBetweenSlidePaginationAndRatingSlide += 55;
      }

      this.$refs.ratingSlide.style.paddingBottom = slidePaginationHeight + slidePaginationBottom + spaceBetweenSlidePaginationAndRatingSlide + "px";
    },
    truncateOnlyOnMobile: function (text, length, suffix) {
      if (text.length > length && window.screen.width <= this.truncateTitleUnderWidthSize) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
    onWidthChange() {
      document.getElementById('rating-slide__title').innerText = this.truncateOnlyOnMobile(this.title, this.truncateTitleLength, '...');
      document.getElementById('rating-slide__subtitle').innerText = this.truncateOnlyOnMobile(this.subtitle, this.truncateTitleLength, '...');
      document.getElementById('rating-slide__fragrance-ui-pill').innerText = this.truncateOnlyOnMobile(this.fragrance, this.truncateTitleLength, '...');
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/typography.scss";
@import "@/styles/colors.scss";

$md: 640px;

.rating-slide {
  background: #0000000d;
  min-width: 100vw;
  min-height: 100vh;
  padding-top: var(--s-16);
  padding-bottom: var(--s-16);
  padding-left: var(--s-2);
  padding-right: var(--s-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--s-8);

  &__fragrance {
    @extend .g-text-h2;
  }

  &__mid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto 0;
  }

  &__title {
    @extend .g-text-h1;
  }

  &__subtitle {
    @extend .g-text-body-large;
    margin-top: 15px;
    @media (max-width: $md) {
      margin-top: 5px;
    }
  }

  &__selector {
    margin-top: 50px;
    @media (max-width: $md) {
      margin-top: 40px;
      --size: 32px;
    }
  }

  &--dark {
    background: #ffffff1a;
    color: #ffffff;

    .rating-slide__fragrance {
      background: #000000;
    }

    .rating-slide__fragrance {
      background: #000000;
    }

    .rating-slide__selector {
      background: #000000;

      &__item {
        img {
          stroke: white;
        }
      }
    }
  }
}
</style>
