<template>
  <div
    class="vh flex flex-col items-center text-center pt-12 pbx-9 sm:flex-row"
  >
    <div
      class="title-image-container flex flex-col flex-1 items-center w-full max-h-half sm:h-full sm:max-h-full"
    >
      <h1 class="g-text-h1 sm:flex-1 sm:flex sm:items-center">
        <span>{{ title }}</span>
      </h1>
      <div
        class="w-full flex-1 sm:h-full"
        :style="
          `background: url('${image}') center / contain no-repeat; min-height: 20vh;`
        "
      />
    </div>

    <div
      class="text-container text-left w-full max-h-full overflow-y-auto px-10 sm:mt-7 sm:pl-4"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FragrancesModalSlide",
  props: {
    image: String,
    title: String,
  },
};
</script>

<style scoped lang="scss">
.vh {
  height: calc(var(--vh, 1vh) * 100 - 45px);
}

.title-image-container {
  @screen sm {
    h1 {
      max-height: 40%;
    }
    div {
      max-height: 60%;
    }
  }
}

.text-container {
  @screen sm {
    height: calc(100% - 1.75rem); // margin causes miscalculation on height
    width: 75%;
  }
  @screen lg {
    width: 65%;
  }
}
</style>
