<template>
  <div class="g-text-body-regular h-full">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "@/styles/global.scss";
@import "@/styles/typography.scss";
@import "@/styles/colors.scss";
@import "@/styles/spacing.scss";
@import "@/styles/misc.scss";
@import "@/styles/ui-utilities.scss";
</style>
