<template>
  <Loader
    v-if="!loaderDestroy"
    :loader="loaderSettings"
    :loadClass="loader"
    :showGeneric="loadingDefaultSplash"
  />
  <FragranceExperience
    v-if="success && !error"
    :gallery="content.gallery"
    :title="content.title"
    :imageTitle="content.imageTitle"
    :perfumeDescriptions="content.perfumeDescriptions"
    :fragranceStages="content.fragranceStages"
    :fragranceStagesDefaultSettings="content.fragranceStagesDefaultSettings"
    :evaporationSlides="content.evaporationSlides"
    :overlayConfiguration="content.overlayConfiguration"
    :theme="content.theme"
  />
  <Page404 v-if="error" />
</template>

<script>
import FragranceExperience from "../components/FragranceExperience.vue";
import Loader from "../components/Loader.vue";
import Page404 from "../views/Page404.vue";
const NO_DURATION = "nod";

export default {
  name: "Product",
  components: {
    FragranceExperience,
    Loader,
    Page404,
  },
  async created() {
    this.time = new Date();
    await this.fetchProduct();
    if (this.splashScreen === null) {
      await this.fetchSplashScreen();
    }
    this.loadingDefaultSplash = false;
  },
  data: () => ({
    loaderTime: 2000,
    error: false,
    success: false,
    loader: true,
    loaderDestroy: false,
    theme: "light",
    splashScreen: null,
    loadingDefaultSplash: true,
    loaderSettingsDefaults: {
      headerText: "",
      logoSrc: "logo_splashscreen_desktop.png",
      bottomLogoSrc: "logo-givaudan-human-by-nature.png",
      logoName: "SmellViz",
      backgroundSrc: "img_smell_viz_desktop.png",
      contact: {
        link: "gael.trinquart@qivaudan.com",
        text: "gael.trinquart@qivaudan.com",
      },
      footerTitle: "engage your senses",
    },
    content: {
      evaporationSlides: [],
      overlayConfiguration: {
        textColor: "text-white",
        pillTextColor: "bg-black",
        pillBackgroundColor: "text-white",
        timelineColor: "bg-white",
        timelineTextColor: "text-black",
      },
      fragranceStages: [],
      fragranceStagesDefaultSettings: [
        {
          BASE: {
            time: 480,
            unit: "h",
            intensity: [55, 80, 100],
            isVisible: true,
          },
          HEART: {
            time: 30,
            unit: "min",
            intensity: [25, 50, 75],
            isVisible: true,
          },
          HEAD: {
            time: 2,
            unit: "sec",
            intensity: [0, 20, 45],
            isVisible: true,
          },
        }
      ]
    },
  }),
  computed: {
    loaderSettings() {
      if (!this.splashScreen || Object.keys(this.splashScreen).length === 0) {
        return this.loaderSettingsDefaults;
      }

      return {
        headerText: "",
        logoSrc: this.splashScreen.logo,
        logoName: this.splashScreen.text,
        backgroundSrc: this.splashScreen.background,
        contact: {
          link: "gael.trinquart@qivaudan.com",
          text: "gael.trinquart@qivaudan.com",
        },
        footerTitle: "engage your senses",
      };
    },
  },
  methods: {
    async fetchProduct() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_ROOT_API}/experience/${this.$route.params.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          this.mergeResponse(data);
        } else {
          this.setError();
        }
      } catch (e) {
        console.error(e);
        this.setError();
      }
    },
    async fetchSplashScreen() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_ROOT_API}/default-splash`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();

          if (data.data) {
            this.splashScreen = data.data;
          } else {
            throw new Error('No "data" property found on the return JSON');
          }
        } else {
          throw new Error("Invalid response code for splash screen");
        }
      } catch (e) {
        console.error(e);
      }
    },
    mergeResponse({ data }) {
      const contentClone = JSON.parse(JSON.stringify(this.content));

      // set splash screen from product if needed
      if (data.splash_screen) {
        this.splashScreen = data.splash_screen.data;
      }
      contentClone.imageTitle = data.image;
      contentClone.title = data.name;
      contentClone.theme = data.theme ? data.theme.toLowerCase() : "light";

      contentClone.duration = [];

      for (const d in data.slides) {
        contentClone.duration.push({
          BASE: {
            time: data.slides[d].evaporation?.base_time,
            unit: data.slides[d].evaporation?.base_time_unit,
          },
          HEART: {
            time: data.slides[d].evaporation?.heart_time,
            unit: data.slides[d].evaporation?.heart_time_unit,
          },
          HEAD: {
            time: data.slides[d].evaporation?.head_time,
            unit: data.slides[d].evaporation?.head_time_unit,
          },
        });
      }

      contentClone.fragranceStagesDefaultSettings = [];

      contentClone.duration.forEach((duration) => {
        let fragranceStagesSettings = {};

        Object.keys(duration).forEach((codeStage) => {
          fragranceStagesSettings[codeStage] = {
            time: duration[codeStage].time,
            unit: duration[codeStage].unit,
            intensity: this.content.fragranceStagesDefaultSettings[0][codeStage].intensity,
            isVisible: duration[codeStage].time && duration[codeStage].unit != NO_DURATION,
          }
        });

        // set duration time for each slide and stage
        contentClone.fragranceStagesDefaultSettings.push(fragranceStagesSettings);
      });

      contentClone.overlayConfiguration = {
        ...this.content.overlayConfiguration,
        pillTextColor: contentClone.theme === "light" ? "bg-black" : "bg-white",
        pillBackgroundColor:
          contentClone.theme === "light" ? "text-white" : "text-black",
        timelineColor: contentClone.theme === "light" ? "bg-black" : "bg-white",
        timelineTextColor:
          contentClone.theme === "light" ? "text-white" : "text-black",
        titleTextColor:
          contentClone.theme === "light" ? "text-black" : "text-white",
        titleBackgroundColor:
          contentClone.theme === "light" ? "bg-white" : "bg-black",
      };

      contentClone.gallery = data.slides.map((item, index) => {
        if (item.type === "EVAPORATION") contentClone.evaporationSlides.push(index);

        const slideData = {
          id: item.id,
          src: item.media,
          type: null,
          seq: item.seq,
        };

        switch (item.type) {
          case "EVAPORATION":
            // evaporation slide needs to be mapped to
            // image type to display background properly
            slideData.type = "image";
            break;
          case "VIDEO":
            slideData.type = "video/mp4";
            break;
          case "IMAGE":
            slideData.type = "image";
            break;
          case "RATING":
            slideData.type = "rating";
            // here we are mutating the object to inject rating config
            slideData.rating = item.rating;
            break;
          case "QUESTIONNAIRE":
            slideData.type = "questionnaire";
            slideData.questionnaire = item.questionnaire;
            break;
          case "HOLOGRAM":
            slideData.type = "hologram";
            slideData.hologram = item.hologram;
            break;
          case "BUY":
            slideData.type = "buy";
            slideData.buy = item.buy;
            break;
          default:
            slideData.type = "image";
        }

        return slideData;
      });

      const fragranceStageCodes = Object.keys(contentClone.fragranceStagesDefaultSettings[0]);

      data.slides.forEach((slide, slideIndex) => {
        let ingredients = {};

        fragranceStageCodes.forEach((stageCode, key) => {
          ingredients[key] = [];
        });

        slide.evaporation?.fragrances.forEach((item) => {
          const stageCodeIndex = fragranceStageCodes.indexOf(item.note);
          ingredients[stageCodeIndex].push({
            fragrance: item.fragrance,
            note: item.note
          });
        });

        contentClone.fragranceStages[slideIndex]= {
          ingredients: ingredients,
          isInvert: slide.evaporation?.invert ?? 0
        };
      });

      const invertedIngredientOrder = this.invertIngredientsOrder(contentClone.fragranceStages, contentClone.fragranceStagesDefaultSettings);

      contentClone.fragranceStages = invertedIngredientOrder[0];
      contentClone.fragranceStagesDefaultSettings = invertedIngredientOrder[1];

      contentClone.perfumeDescriptions = {};
      if (data.brand) {
        contentClone.perfumeDescriptions.brand = {
          value: data.brand,
          text: "Brand name",
        };
      }
      if (data.customer) {
        contentClone.perfumeDescriptions.customer = {
          value: data.customer,
          text: "Customer",
        };
      }
      if (data.ff_house) {
        contentClone.perfumeDescriptions.ff_house = {
          value: data.ff_house,
          text: "F&F House",
        };
      }
      if (data.country) {
        contentClone.perfumeDescriptions.country = {
          value: data.country,
          text: "Country",
        };
      }
      if (data.launch) {
        contentClone.perfumeDescriptions.launch = {
          value: data.launch,
          text: "Launch date",
        };
      }
      if (data.perfumer) {
        contentClone.perfumeDescriptions.perfumer = {
          value: data.perfumer,
          text: "Perfumer",
        };
      }
      if (data.cost) {
        contentClone.perfumeDescriptions.cost = {
          value: data.cost,
          text: "Cost",
        };
      }
      if (data.storytelling) {
        contentClone.perfumeDescriptions.storytelling = {
          value: data.storytelling,
        };
      }

      this.content = contentClone;

      this.success = true;
      const nowTime = new Date() - this.time;

      setTimeout(
        () => {
          this.loader = false;

          setTimeout(() => {
            this.loaderDestroy = true;
          }, 550);
        },
        nowTime < this.loaderTime ? this.loaderTime - nowTime : 0
      );
    },
    setError() {
      this.error = true;

      const nowTime = new Date() - this.time;

      setTimeout(
        () => {
          this.loader = false;

          setTimeout(() => {
            this.loaderDestroy = true;
          }, 400);
        },
        nowTime < this.loaderTime ? this.loaderTime - nowTime : 0
      );
    },
    invertIngredientsOrder(fragranceStages, fragranceStagesDefaultSettings) {
      let copyFragranceStages = fragranceStages;

      for (const index in fragranceStages) {
        if (fragranceStages[index].isInvert) {
          let ingredients = {};
          let stageCodeIndex = 0;
          let revertFragranceStageSettings = {};

          for (let i = Object.keys(fragranceStages[index].ingredients).length - 1; i >= 0; i--) {
            ingredients[stageCodeIndex] = [];

            for (let j = fragranceStages[index].ingredients[i].length - 1; j >= 0; j--) {
              ingredients[stageCodeIndex].push(fragranceStages[index].ingredients[i][j]);
            }

            stageCodeIndex++;
          }

          copyFragranceStages[index].ingredients = ingredients

          const fragranceStagesDefaultSettingsKeys = Object.keys(fragranceStagesDefaultSettings[index]);

          for (let f = fragranceStagesDefaultSettingsKeys.length - 1; f >= 0; f--) {
            revertFragranceStageSettings[fragranceStagesDefaultSettingsKeys[f]] = fragranceStagesDefaultSettings[index][fragranceStagesDefaultSettingsKeys[f]];
          }

          fragranceStagesDefaultSettings[index] = revertFragranceStageSettings;
        }
      }

      return [copyFragranceStages, fragranceStagesDefaultSettings];
    },
},
};
</script>
