<template>
  <div class="fixed z-20" :class="{ hidden: hidden }">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-dark"></div>
    </div>

    <div class="fixed inset-0 pb-3 text-white">
      <div class="absolute w-full pt-1">
        <button
          class="absolute cursor-pointer top-2 right-2 sm:right-6 sm:top-6 z-40"
          @click="close"
        >
          <Icon class="w-12 h-12" icon="mdi:close" />
        </button>
      </div>
      <splide
        ref="slider"
        :options="splideOptions"
        @splide:active="({ _i }) => (activeSlide = _i)"
      >
        <splide-slide>
          <FragrancesModalSlide :title="title" :image="imageTitle">
            <div
              :class="{
                'border-b':
                  index != Object.keys(perfumeDescriptions).length - 1,
              }"
              class="
                border-dark-lighter
                pb-2
                mb-3
                
              "
              v-for="(value, key, index) in perfumeDescriptions"
              v-bind:key="key"
            >
              <span v-if="value.text">{{ value.text }}: </span>
              <strong v-if="value.text && value.value">{{
                value.value
              }}</strong>
              <span class="storytelling" v-if="!value.text && value.value">{{
                value.value
              }}</span>
            </div>
          </FragrancesModalSlide>
        </splide-slide>
        <splide-slide
          v-for="ingredient in ingredients"
          v-bind:key="ingredient.name"
        >
          <FragrancesModalSlide
            :title="ingredient.name"
            :image="ingredient.image"
          >
            <span
              class="g-text-body-regular"
              v-html="ingredient.description.replaceAll('\n', '<br>')"
            />
          </FragrancesModalSlide>
        </splide-slide>
      </splide>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import FragrancesModalSlide from "./FragrancesModalSlide.vue";

export default {
  name: "FragrancesModal",
  components: {
    FragrancesModalSlide,
    Icon,
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize);

    for (const slide in this.fragranceStages) {
      for (const stage in this.fragranceStages[slide]) {
        for (const ingredient in this.fragranceStages[slide][stage]) {
          for (const fragrance in this.fragranceStages[slide][stage][ingredient]) {
            const fragranceData = this.fragranceStages[slide][stage][ingredient][fragrance].fragrance;

            if (fragranceData.description && !this.ingredients.some(i => i.name === fragranceData.name)) {
              this.ingredients.push(fragranceData);
            }
          }
        }
      }
    }
    // this.fragranceStages.forEach((fragranceStage) => {
    //   fragranceStage.ingredients.forEach((ingredientsStage) => {
    //     ingredientsStage.forEach((ingredient) => {
    //       if (ingredient.description && !this.ingredients.some(i => i.name === ingredient.name)) {
    //         this.ingredients.push(ingredient);
    //       }
    //     });
    //   });
    // });

    this.$refs.slider.remount();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
  },
  data: () => ({
    hidden: true,
    splideOptions: {
      arrows: false,
      width: "100%",
    },
    activeSlide: -1,
    ingredients: [],
  }),
  props: {
    fragranceStages: Array,
    title: String,
    imageTitle: String,
    perfumeDescriptions: Object,
  },
  methods: {
    close() {
      this.hidden = true;
      this.activeSlide = -1;
    },

    async openSlide(ingredient) {
      let slideNumber = 0;
      let value;

      this.ingredients.forEach((i, index) => {
        if (i.name === ingredient.name) {
          slideNumber = index + 1;
        }
      });

      if (slideNumber === 0) {
        value = this.title;
      } else {
        value = this.ingredients[slideNumber - 1].name;
      }
      this.$gtm.trackEvent({
        event: "ingredient_clicked",
        category: "Ingredient",
        action: "click",
        label: "Ingredient clicked",
        value: value,
        noninteraction: false,
      });
      this.hidden = false;
      await this.$nextTick();
      this.$refs.slider.go(slideNumber);
    },
    resize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
  watch: {
    activeSlide: function(newValue) {
      if (newValue !== -1) {
        this.$gtm.trackEvent({
          event: "ingredient_slide_displayed",
          category: "Ingredient",
          action: "slide",
          label: "Ingredient slide",
          value:
            newValue === 0 ? this.title : this.ingredients[newValue - 1].name,
          noninteraction: false,
        });
      }
    },
  },
};
</script>
<style>
.storytelling {
  white-space: pre-line;
}
</style>
