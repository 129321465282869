<template>
  <div
    class="loader"
    :class="{ 'show-loader': loadClass }"
    :style="loaderStyle"
  >
    <template v-if="!showGeneric">
      <div class="logo">
        <div v-if="loader && loader.logoName" class="logo-name">
          {{ loader.logoName }}
        </div>
        <img v-if="logoSrc" :src="logoSrc" alt="logo" />
      </div>
      <div class="bottom-logo">
        <img v-if="bottomLogoSrc" :src="bottomLogoSrc" alt="human by nature" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    loader: Object,
    loadClass: Boolean,
    showGeneric: Boolean,
  },
  computed: {
    logoIsUrl() {
      return this.isValidHttpUrl(this.loader.logoSrc);
    },
    bottomLogoIsUrl() {
      return this.isValidHttpUrl(this.loader.bottomLogoSrc);
    },
    backgroundIsUrl() {
      return this.isValidHttpUrl(this.loader.backgroundSrc);
    },
    loaderStyle() {
      if (this.showGeneric) {
        return {
          "background-color": "black",
        };
      }
      return {
        backgroundColor: "black",
        backgroundImage: this.backgroundIsUrl
          ? `url(${this.loader.backgroundSrc})`
          : `url(${require("@/assets/" + this.loader.backgroundSrc)})`,
      };
    },
    logoSrc() {
      if (!this.loader.logoSrc) {
        return null;
      }

      return this.logoIsUrl
        ? this.loader.logoSrc
        : require("@/assets/" + this.loader.logoSrc);
    },
    bottomLogoSrc() {
      if (!this.loader.bottomLogoSrc) {
        return null;
      }

      return this.bottomLogoIsUrl
          ? this.loader.bottomLogoSrc
          : require("@/assets/" + this.loader.bottomLogoSrc);
    },
  },
  methods: {
    isValidHttpUrl(string) {
      let url;

      try {
        url = new URL(string);
      } catch (e) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/typography.scss";

.loader {
  background-size: cover;
  background-position: bottom center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  font-size: 20px;
  opacity: 0;
  transition: opacity 0.5s;
  color: white;
  padding: 50px 0 45px;

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: 170px;
    background: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.6) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &::after {
    top: auto;
    bottom: 0;
    background: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 50%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &.show-loader {
    opacity: 1;
    transition: 0;
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      margin-top: 20px;

      @media (orientation: portrait) {
        width: 80%;
      }

      @media (orientation: landscape) {
        height: 80%;
      }
    }
  }

  .bottom-logo {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    img {
      width: 250px;

      @media (max-width: 768px) {
        width: 150px;
      }
    }
  }

  .logo-name {
    @extend .g-text-display;
    color: var(--white);
    text-align: center;
    max-width: 400px;
    font-weight: 400;
  }
}
</style>
