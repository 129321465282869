<template>
  <div
    class="buy-slide"
    :class="{ 'buy-slide--dark': theme === 'dark' }"
    :style="{ 'background-image': 'url(' + background + ')' }"
    ref="buySlide"
  >
    <a :href="cta_url" target="_blank" v-on:click="handleClick">
      <ui-button variant="solid" size="large">
        {{ cta_text }}
      </ui-button>
    </a>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "BuySlide",
  components: {
    UiButton,
  },
  props: ["slideId", "background", "cta_text", "cta_url", "theme"],
  mounted () {
    const self = this;

    setTimeout(function () {
      self.setBuyButtonPosition();
    }, 300);

    window.addEventListener('load', this.setBuyButtonPosition);
    window.addEventListener('resize', this.setBuyButtonPosition);
  },
  beforeUnmount() {
    window.removeEventListener('load', this.setBuyButtonPosition);
    window.removeEventListener('resize', this.setBuyButtonPosition);
  },
  methods: {
    handleClick() {
      this.$gtm.trackEvent({
        event: "buy_clicked",
        category: "Buy",
        action: "click",
        label: "Buy slide clicked",
        value: this.slideId + "-" + this.cta_text,
        noninteraction: false,
      });
    },
    setBuyButtonPosition() {
      const slidePagination = document.getElementsByClassName("splide__pagination smellviz-pagination")[0];
      const slidePaginationHeight = slidePagination.getBoundingClientRect().height;
      const slidePaginationStyle = window.getComputedStyle(slidePagination);
      const slidePaginationBottom = parseInt(slidePaginationStyle.getPropertyValue("bottom"));
      const spaceBetweenSlidePaginationAndBuySlide = 10;

      this.$refs.buySlide.style.paddingBottom = slidePaginationHeight + slidePaginationBottom + spaceBetweenSlidePaginationAndBuySlide + "px";
    }
  },
};
</script>

<style lang="scss" scoped>
.buy-slide {
  background: #0000000d;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding-bottom: calc(35px + 0.5em);
}
</style>
